"use client";

import { useEffect } from "react";

const MatomoScriptLoader: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      var _mtm = ((window as any)._mtm = (window as any)._mtm || []);
      _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });

      const d = document;
      const g = d.createElement("script");
      const s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src =
        "https://cdn.matomo.cloud/gocoregg.matomo.cloud/container_wujHRxV4.js";
      s.parentNode?.insertBefore(g, s);
    }
  }, []);

  return null;
};

export default MatomoScriptLoader;
